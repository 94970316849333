<template>
  <v-dialog v-model="dialogOpen" width="600">
    <template v-slot:activator="{ on: dialogOn }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn icon v-on="{ ...dialogOn, ...tooltipOn }">
            <v-icon v-if="comments.length > 0">fas fa-comments-alt</v-icon>
            <v-icon v-else>fal fa-comments-alt</v-icon>
          </v-btn>
        </template>
        <span v-if="comments.length > 0">View Comments or Add a New Comment</span>
        <span v-else>Add a Comment</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Form Submission Comments</v-card-title>
      <v-card-text>
        <v-form ref="commentFormRef">
          <v-textarea v-model="newComment" :rules="[ v => v !== '' || 'Required' ]" label="New Comment" outlined></v-textarea>
          <v-checkbox v-model="newPriv" label="Make this comment private (only visible to me and form admins)" style="margin-top:-8px"></v-checkbox>
        </v-form>
        <v-btn color="primary" @click="addComment">Add Comment</v-btn>
      </v-card-text>
      <comment-list :submission-id="submissionId" :access-role="accessRole"></comment-list>
      <v-card-actions>
        <v-btn text @click="dialogOpen = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  props: {
    submissionId: {
      type: String,
      required: true
    },
    accessRole: {
      type: String,
      required: true
    }
  },
  components: {
    CommentList: () => import('./CommentList')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const dialogOpen = ref(false)
    const comments = ref([])
    const commentFormRef = ref(null)
    const newComment = ref('')
    const newPriv = ref(false)
    async function addComment () {
      if (commentFormRef.value.validate()) {
        const data = await root.$feathers.service('forms/submission').patch(props.submissionId, { $push: { comments: { pidm: user.value.pidm, user: user.value.username, date: new Date(), comment: newComment.value, priv: newPriv.value } } })
        comments.value = data.comments
        commentFormRef.value.reset()
      }
    }

    root.$feathers.service('forms/submission').on('patched', (data) => {
      if (data._id === props.submissionId) {
        comments.value = data.comments
      }
    })

    watch(() => props.submissionId, async () => {
      const data = await root.$feathers.service('forms/submission').get(props.submissionId)
      if ('comments' in data && Array.isArray(data.comments)) comments.value = data.comments
      else comments.value = []
    })

    return {
      user,
      dialogOpen,
      comments,
      commentFormRef,
      newComment,
      newPriv,
      addComment
    }
  }
}
</script>
